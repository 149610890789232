import React, {useState, useEffect} from 'react';
import {Route, Switch} from 'react-router-dom';
import {connect} from 'react-redux';
import {Container, Row} from 'reactstrap';
import axios from './axios-base';
import Aux from './hoc/Aux/Aux';
import Home from './containers/Home/Home';
import SignUp from './containers/SignUp/SignUp';
import AchievementsOverview from './containers/Achievements/AchievementsOverview';
import ProjectList from './containers/ProjectList/ProjectList';
import Project from './containers/Project/Project';
import Page from './components/Page/Page';
import Auth from './containers/Auth/Auth';
import Logout from './containers/Auth/Logout/Logout';
import withErrorHandler from './hoc/withErrorHandler/withErrorHandler';
import * as actions from './store/actions/index';
import Header from './components/Header/Header';
import Footer from './components/Footer/Footer';
import ConsentModal from './components/UI/Modal/ConsentModal/ConsentModal';
import Cookies from 'js-cookie';
import {Helmet} from "react-helmet";
import {personalization, statisticTracking} from "./shared/trackingScripts";
import ScrollToTop from './shared/scrollToTop';
import PageNotFound from './components/Page/PageNotFound.js';
import SMSRequest from "./containers/SMSRequest/SMSRequest";
import {sortFundingStages} from "./shared/fundingConfigurationFinder";

function App (){
    const [pageNotFound, setPageNotFound] = useState(false);
    const [data, setData] = useState(null);

    const pageNotFoundCallback = (pageState) => {
        setPageNotFound(pageState);
    };

    useEffect(() => {
        //todo: Potentially modify promise to allow for offline use
        Promise.all([
            axios.get('/pages/'),
            axios.get('/menus/'),
            axios.get('/accounts/funding-pool'),
            axios.get('/config')
        ])
        .then(([pages, menus, fundingPool, clientConfiguration]) => {

            const sortedFundingData = sortFundingStages(clientConfiguration.data.funding)
            clientConfiguration.data.funding = sortedFundingData;
            setData({
                pages: pages.data,
                menus: menus.data,
                fundingPool: {...fundingPool.data},
                clientConfiguration: {...clientConfiguration.data}
            });
        });
    }, []);

    let dynamicPages = null;
    let dynamicHeader = null;
    let consentManagementModal = null;
    let pageNotFoundRoute = null;
    let header = null;
    let footer = null;
    let projectRoute = null;
    let receiveCodeRoute = null;
    let projectListRoute = null;
    let homeRoute = null;

    if (data) {
        // Check if cookies exist and display consent management modal OR load tracking scripts
        if (Cookies.get('bigfoot_personalization-consent') === 'true' && Cookies.get('bigfoot_statistic-consent') === 'true') {
            dynamicHeader = <Helmet>{personalization()}{statisticTracking()}</Helmet>
        } else if (Cookies.get('bigfoot_statistic-consent') === 'true') {
            dynamicHeader = <Helmet>{statisticTracking()}</Helmet>
        } else if (Cookies.get('bigfoot_personalization-consent') === 'true') {
            dynamicHeader = <Helmet>{personalization()}</Helmet>
        }
        if (Cookies.get('bigfoot_cookie-consent') !== 'true') { consentManagementModal = <ConsentModal pages={data.pages}/> }
        pageNotFoundRoute = <Route path="*" status={404}
                                   render={() => <PageNotFound pageNotFoundCallback={pageNotFoundCallback}/>}/>;
        dynamicPages = (
            data.pages.map((page, index) => {
                return (
                    /* The following component is monitored by Font Awesome dom.watch(). Check children */
                    <Route
                        key={index}
                        exact //Caution: This may redirect to 404 pag if embedded links are opened from the page.
                        path={`/${page.slug}`}
                        render={(props) => <Row id="dom-watch"><Page pageTitle={page.title}
                                                                     pageBody={page.body}
                                                                     survey={page.survey} {...props}/></Row>}
                    />
                );
            })
        )
        const primaryNavigation = data.menus.filter(menuItem => {
            return menuItem.position === 'primary' && menuItem.highlight === false
        });
        const primaryNavigationCTA = data.menus.filter(menuItem => {
            return menuItem.position === 'primary' && menuItem.highlight === true
        });
        const secondaryNavigation = data.menus.filter(menuItem => {
            return menuItem.position === 'secondary'
        });
        header = (<Header
            votingPhases={data.clientConfiguration.phases}
            balance={data.fundingPool.balance}
            menus={data.menus}
            primaryNavigation={primaryNavigation}
            primaryNavigationCTA={primaryNavigationCTA}
            pageNotFound={pageNotFound}
        />);
        footer = (<Footer secondaryNavigation={secondaryNavigation}/>);

        projectRoute = (
            <Route
                path="/projekte/:id"
                render={(props) => <Project
                    balance={data.fundingPool.balance}
                    votingPhases={data.clientConfiguration.phases}
                    fundingStages={data.clientConfiguration.funding}
                />}/>

        );
        receiveCodeRoute = (
            <Route path="/code-anfordern"
                   render={(props) => <SMSRequest balance={data.fundingPool.balance}
                                                  votingPhases={data.clientConfiguration.phases} {...props} />}/>
        );
        projectListRoute = (
            <Route path="/projekte" render={() =>
                <ProjectList
                    votingPhases={data.clientConfiguration.phases}
                    balance={data.fundingPool.balance}
                    fundingStages={data.clientConfiguration.funding}
                />
            }/>
        );
        homeRoute = (
            <Route path="/" exact render={(props) => <Home votingPhases={data.clientConfiguration.phases} {...props} />} />
        );
    }
    return (
        <Aux>
            <Container>
                {dynamicHeader}
                {consentManagementModal}
                {header}
                <ScrollToTop />
                <Switch>
                    {dynamicPages}
                    {projectRoute}
                    {receiveCodeRoute}
                    {projectListRoute}
                    <Route path="/errungenschaften" component={AchievementsOverview}/>
                    <Route path="/bewerbung" render={(props) => <SignUp {...props} />} />
                    <Route path="/logout" component={Logout}/>
                    <Route path="/login" component={Auth}/>
                    {homeRoute}
                    {pageNotFoundRoute}
                </Switch>
                {footer}
            </Container>
        </Aux>
    );
}


const mapDispatchToProps = dispatch => {
    return {
        onTryAutoSignup: () => dispatch(actions.authCheckState())
    }
};

export default connect(null, mapDispatchToProps)(withErrorHandler(App, axios));
