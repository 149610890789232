import React from 'react';
import {Alert, Col} from 'reactstrap';
import { NavLink } from 'react-router-dom';

const VotePlaceholder = ({messageText, messageType, messageLink}) => {

    const AlertMessage = <Alert color={messageType} className="text-center">{messageText}</Alert>;

    return (
      <Col>
          {messageLink ? <NavLink to={messageLink}>{AlertMessage}</NavLink> : AlertMessage}
      </Col>
    );
}

export default VotePlaceholder;