import React, {Fragment, Component} from 'react';
import {Progress} from 'reactstrap';
import classes from './ProgressBar.module.scss';
import CountUp from "react-countup";
import {
    scaleVotesToProgress,
    fundingMessage,
    findNextFundingStageIndex
} from "./ProgressBar.helper";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faCoins} from '@fortawesome/free-solid-svg-icons'

class ProgressBar extends Component {
    constructor(props) {
        super(props);
        this.state = {
            voteCount: 0
        }
        this.progressBarWidth = React.createRef();
    }

    componentDidMount() {
        this.setState({ voteCount: this.props.voteCount });
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (prevProps.voteCount !== this.props.voteCount){
            this.setState({voteCount: this.props.voteCount})
        }
    }

    renderBubble(scaledProgress, BadgeDynamicStyles, pointerDynamicStyles){
        return (
            <React.Fragment>
                <h3 style={BadgeDynamicStyles} className={"bg-secondary text-light rounded p-1 " + classes.badge} color="text-secondary">
                    {this.state.voteCount >= 0 ? <CountUp start={0} end={this.state.voteCount} duration={2.4} separator="" delay={1}/> : '-'} Stimmen
                </h3>
                <div style={pointerDynamicStyles} className={classes.pointer}/>
            </React.Fragment>
        );
    };

    render() {
        let currentFundingGoalText = '...';
        let scaledProgress = 0;
        const { fundingStages, cssClasses, bubble } = this.props;
        const PROGRESS_BAR_CONTAINER_WIDTH = this.progressBarWidth.current ? this.progressBarWidth.current.clientWidth : 0;

        let progressBarLabels;
        let nextStageIndex;
        if(fundingStages){
            const lastFundingStage = fundingStages[fundingStages.length - 1];

            scaledProgress =  scaleVotesToProgress(this.state.voteCount, lastFundingStage.threshold);

            nextStageIndex = findNextFundingStageIndex(this.state.voteCount, fundingStages);
            currentFundingGoalText = fundingMessage(nextStageIndex, fundingStages);

            progressBarLabels = () => {
                return fundingStages.map((stage, i) => {
                    let thresholdCrossed = false;
                    if(this.state.voteCount >= stage.threshold){
                        thresholdCrossed = true;
                    }
                    const currentPos = stage.threshold / lastFundingStage.threshold * 100;
                    const animationDelay = stage.threshold / this.state.voteCount * 2.4; // 2.4 based on the animation duration of progress bar
                    return (
                        <Fragment key={i}>
                            <div style={{left: `${currentPos}%`}} className={classes.thresholdPointer}/>
                            <FontAwesomeIcon
                                style={{
                                    left: `${currentPos}%`,
                                    animationDelay: `${animationDelay}s`
                                }}
                                data-testid="threshold-icon"
                                icon={faCoins}
                                size="1x"
                                className={`me-2 ${classes.threshold1Label} ${classes.thresholdLabel} ${thresholdCrossed && classes.iconAnimation}`}/>
                        </Fragment>
                    );
                });
            }
        }
        const BadgeDynamicStyles = { transform: `translateX(-${scaledProgress}%)` };
        const pointerDynamicStyles = { transform: `rotate(${(19 - (38 / 100 * scaledProgress))}deg)` }
        const textGradientStop = () => { return (scaledProgress / 100 * PROGRESS_BAR_CONTAINER_WIDTH) -16; }
        let textGradientStyle = { background: `linear-gradient(to right, #fff 0%, #fff ${textGradientStop() + 'px'}, #333 ${textGradientStop() + 'px'}, #333 100%)` }

        return(
            <React.Fragment>
                <div className={cssClasses + ' ' + classes.progressBarFrame + " mx-2"} ref={this.progressBarWidth}>
                    <Progress className={`${classes.progress} rounded-0`} style={{height: "0.875rem"}} multi>
                        <Progress
                            bar
                            className={classes.progressBar + ` ${(scaledProgress === 100 && " rounded-0 ")}`}
                            color={nextStageIndex === -1 ? "success" : "primary"}
                            value={scaledProgress}
                        >
                            {bubble && this.renderBubble(scaledProgress, BadgeDynamicStyles, pointerDynamicStyles)}

                        </Progress>
                        {!bubble &&
                            <p style={textGradientStyle} className={"text-uppercase " + classes.progressText}>
                                {this.state.voteCount >= 0 ? this.state.voteCount : '-'} Stimmen
                            </p>
                        }

                    </Progress>
                        <div className={classes.progressLabels}>
                            {progressBarLabels && progressBarLabels()}
                        </div>
                    </div>

                <div className="mt-5 mb-2 d-flex justify-content-end">
                    {currentFundingGoalText}
                </div>
            </React.Fragment>
        );
    }
}

export default ProgressBar;
