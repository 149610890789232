import React, {useState, useEffect, useLayoutEffect} from 'react';
import {Card, CardBody, CardImg, CardText, CardTitle, Col, Row} from 'reactstrap';
import {Helmet} from 'react-helmet';
import {sendVirtualPageView} from '../../shared/utility';
import KeyVisual from '../../components/UI/ResponsiveImageSet/ResponsiveImageSet';
import KeyVisualXs from '../../assets/images/keyvisual-xs-rgb.jpg';
import KeyVisualLg from '../../assets/images/keyvisual-lg-rgb.jpg';
import AchievementFacebook from '../../assets/images/cassiopeia-badge-facebook.svg';
import AchievementOutlineFacebook from '../../assets/images/cassiopeia-badge-facebook-outline.svg';
import AchievementWhatsApp from '../../assets/images/cassiopeia-badge-whatsapp.svg';
import AchievementOutlineWhatsApp from '../../assets/images/cassiopeia-badge-whatsapp-outline.svg';
import AchievementIsReferral from '../../assets/images/cassiopeia-badge-is-referral.svg';
import AchievementOutlineIsReferral from '../../assets/images/cassiopeia-badge-is-referral-outline.svg';
import AchievementHasReferred from '../../assets/images/cassiopeia-badge-has-referred.svg';
import AchievementOutlineHasReferred from '../../assets/images/cassiopeia-badge-has-referred-outline.svg';
import AchievementEmail from '../../assets/images/cassiopeia-badge-email.svg';
import AchievementOutlineEmail from '../../assets/images/cassiopeia-badge-email-outline.svg';
import AchievementTwitter from '../../assets/images/cassiopeia-badge-twitter.svg';
import AchievementOutlineTwitter from '../../assets/images/cassiopeia-badge-twitter-outline.svg';
import axios from '../../axios-base';
import { useLocation } from 'react-router-dom';

const AchievementsOverview = () => {
    const location = useLocation();
    const achievementBadges = {
        facebookShares: {
            visible: AchievementFacebook,
            hidden: AchievementOutlineFacebook
        },
        whatsAppShares: {
            visible: AchievementWhatsApp,
            hidden: AchievementOutlineWhatsApp
        },
        emailShares: {
            visible: AchievementEmail,
            hidden: AchievementOutlineEmail
        },
        hasReferred: {
            visible: AchievementHasReferred,
            hidden: AchievementOutlineHasReferred
        },
        isReferral: {
            visible: AchievementIsReferral,
            hidden: AchievementOutlineIsReferral
        },
        twitterShares: {
            visible: AchievementTwitter,
            hidden: AchievementOutlineTwitter
        },
        // Add more badge configurations for other achievement types if needed
    };

    const [achievements, setAchievements] = useState([]);

    useEffect(() => {
        axios.get('/achievements/summary')
            .then(response => {
                console.log(response.data)
                setAchievements(response.data);
            })
            .catch(error => {
                console.error('Error fetching achievements:', error);
            });
        sendVirtualPageView('Errungenschaften');
    }, []);

    useLayoutEffect(() => {
        // Scroll to anchor point when URL hash changes
        if (location.hash) {
            const scrollToAnchor = () => {
                const anchorId = location.hash.substring(1); // Remove the '#' from the hash
                const anchorElement = document.getElementById(anchorId);

                if (anchorElement) {
                    anchorElement.scrollIntoView({ behavior: 'smooth' });
                }
            };

            // Delay scrolling to ensure DOM updates after data fetch
            const timeoutId = setTimeout(scrollToAnchor, 100); // Adjust delay time as needed

            return () => clearTimeout(timeoutId); // Cleanup on component unmount
        }
    }, [location.hash]); // Trigger this effect when location.hash changes

    return (
        <>
            <Helmet>
                <title>{`${process.env.REACT_APP_PROJECT_TITLE}: Errungenschaften`}</title>
            </Helmet>
            <Row>
                <Col className="px-0">
                    <KeyVisual imageXs={KeyVisualXs} imageLg={KeyVisualLg}/>
                </Col>
            </Row>
            <Row className="my-3">
                <Col>
                    <h1>Errungenschaften</h1>
                </Col>
            </Row>
            <Row className="row-cols-1 row-cols-md-1 g-4">
                {achievements.map(achievement => (
                    <Col>
                        <Card className="mb-3 h-100" key={achievement.type} id={achievement.type}>
                            <Row className="g-0 h-100">
                                <Col xs={2} className="d-flex align-items-center justify-content-center">
                                    <CardImg
                                        src={achievement.hasAchieved ? achievementBadges[achievement.type].visible : achievementBadges[achievement.type].hidden}
                                        className="img-fluid"
                                        style={{height: 'auto', maxWidth: '46px', maxHeight: '46px'}}
                                        alt="Vorschau der Errungenschaft"
                                    />
                                </Col>
                                <Col xs={10}>
                                    <CardBody>
                                        <CardTitle tag="h4">{achievement.name}</CardTitle>
                                        <CardText>
                                            {achievement.description}
                                        </CardText>
                                        <CardText>
                                            <small className="text-primary">
                                                {achievement.hasAchieved === 0 ? (
                                                    <>Wurde bisher von <strong>keinem Projekt</strong> erreicht!</>
                                                ) : (
                                                    <>Wurde bisher von <strong>{achievement.hasAchieved}</strong> {achievement.hasAchieved === 1 ? <strong>Projekt</strong> : <strong>Projekten</strong>} erreicht!</>
                                                )}
                                            </small>
                                        </CardText>
                                    </CardBody>
                                </Col>
                            </Row>
                        </Card>
                    </Col>
                ))}
            </Row>
        </>
    );
};

export default AchievementsOverview;
