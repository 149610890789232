import React, {useEffect, useRef, useState} from 'react';
import {Col, NavLink} from 'reactstrap';
import classes from './Badges.module.scss';
import {NavLink as RRNavLink} from "react-router-dom";

const badgeSets = {
    isReferral: {
        split: true,
        reversed: true,
        badgeRibbon: require('../../../assets/images/cassiopeia-badge-is-referral-ribbon.svg').default,
        badgeIcon: require('../../../assets/images/cassiopeia-badge-is-referral.svg').default,
        badgeOutline: require('../../../assets/images/cassiopeia-badge-is-referral-outline.svg').default,
        badgeCSS: {
            Icon: {top: '14px'},
            Ribbon: {},
        }
    },
    hasReferred: {
        split: true,
        reversed: true,
        badgeRibbon: require('../../../assets/images/cassiopeia-badge-has-referred-ribbon.svg').default,
        badgeIcon: require('../../../assets/images/cassiopeia-badge-has-referred.svg').default,
        badgeOutline: require('../../../assets/images/cassiopeia-badge-has-referred-outline.svg').default,
        badgeCSS: {
            Icon: {top: '14px'},
            Ribbon: {},
        }
    },
    whatsAppShares: {
        split: false,
        reversed: false,
        badgeRibbon: require('../../../assets/images/cassiopeia-badge-ribbon-alt.svg').default,
        badgeIcon: require('../../../assets/images/cassiopeia-badge-whatsapp.svg').default,
        badgeOutline: require('../../../assets/images/cassiopeia-badge-whatsapp-outline.svg').default,
        badgeCSS: {
            Icon: {},
            Ribbon: {top: '20px'}
        }
    },
    facebookShares: {
        split: false,
        reversed: false,
        badgeRibbon: null,
        badgeIcon: require('../../../assets/images/cassiopeia-badge-facebook.svg').default,
        badgeOutline: require('../../../assets/images/cassiopeia-badge-facebook-outline.svg').default,
        badgeCSS: {
            Icon: {},
            Ribbon: {top: '20px'}
        }
    },
    emailShares: {
        split: false,
        reversed: false,
        badgeRibbon: null,
        badgeIcon: require('../../../assets/images/cassiopeia-badge-email.svg').default,
        badgeOutline: require('../../../assets/images/cassiopeia-badge-email-outline.svg').default,
        badgeCSS: {
            Icon: {},
            Ribbon: {top: '20px'}
        }
    },
    twitterShares: {
        split: false,
        reversed: false,
        badgeRibbon: null,
        badgeIcon: require('../../../assets/images/cassiopeia-badge-twitter.svg').default,
        badgeOutline: require('../../../assets/images/cassiopeia-badge-twitter-outline.svg').default,
        badgeCSS: {
            Icon: {},
            Ribbon: {top: '20px'}
        }
    }
    // Add more badge sets here based on your needs
};

const BadgeComponent = ({type, isAchieved}) => {
    const badgeContainerRef = useRef(null);
    const badgeRef = useRef(null);
    const [isAnimating, setIsAnimating] = useState(false);

    useEffect(() => {
        const handleScroll = () => {
            const scrollY = window.scrollY || window.pageYOffset;
            const triggerThreshold = 20;

            if (Math.abs(scrollY) > triggerThreshold && !isAnimating) {
                setIsAnimating(true);

                if (badgeContainerRef.current) {
                    badgeContainerRef.current.classList.add(classes.AnimatedRibbon);
                    setTimeout(() => {
                        badgeContainerRef.current.classList.remove(classes.AnimatedRibbon);
                        setIsAnimating(false);
                    }, 2400);
                }
            }
        };

        const handleOrientationChange = (event) => {
            const {gamma} = event;

            if (badgeContainerRef.current && badgeRef.current) {
                const rotationAngle = gamma * -1;
                const viewportWidth = window.innerWidth;
                const badgeRect = badgeContainerRef.current.getBoundingClientRect();
                const badgePositionRatio = badgeRect.x / viewportWidth;

                const maxRotationRange = 35;
                const minRotationRange = 10;
                const rotationRange = minRotationRange + (maxRotationRange - minRotationRange) * badgePositionRatio;

                const cappedRotationAngle = Math.max(-rotationRange, Math.min(rotationRange, rotationAngle));

                badgeContainerRef.current.style.transform = `rotate(${cappedRotationAngle}deg)`;

                const counterRotationAngle = -cappedRotationAngle;
                badgeRef.current.style.transform = `rotate(${counterRotationAngle}deg)`;
            }
        };

        window.addEventListener('scroll', handleScroll);
        window.addEventListener('deviceorientation', handleOrientationChange);

        return () => {
            window.removeEventListener('scroll', handleScroll);
            window.removeEventListener('deviceorientation', handleOrientationChange);
        };
    }, [isAnimating]);

    if (!badgeSets[type]) {
        return null; // Skip rendering if badge type is not recognized
    }

    const {reversed, badgeRibbon, badgeIcon, badgeOutline, badgeCSS} = badgeSets[type];

    return (
        <Col xs={2} className="p-0">
            <NavLink className={`position-relative anl__badge--${type} ${classes.Z2}`} to={`../errungenschaften#${type}`} tag={RRNavLink} exact>
                <div
                    ref={badgeContainerRef}
                    className={`position-relative ${classes.BadgeContainer} ${classes.Z1}`}
                >
                    {isAchieved && (
                        reversed ? (
                            <>
                                <img
                                    className={`position-absolute mx-auto start-0 end-0 ${isAnimating ? classes.AnimatedRibbon : ''} ${classes.Z1}`}
                                    style={badgeCSS.Ribbon}
                                    src={badgeRibbon}
                                    alt="Ribbon"
                                />
                                <img
                                    ref={badgeRef}
                                    className={`position-absolute mx-auto start-0 end-0 ${isAnimating ? classes.AnimatedIcon : ''} ${classes.Z2}`}
                                    style={badgeCSS.Icon}
                                    src={badgeIcon}
                                    alt="Icon"
                                />
                            </>
                        ) : (
                            <>
                                <img
                                    ref={badgeRef}
                                    className={`position-absolute mx-auto start-0 end-0 ${isAnimating ? classes.AnimatedIcon : ''} ${classes.Z2}`}
                                    style={badgeCSS.Icon}
                                    src={badgeIcon}
                                    alt="Icon"
                                />
                                {badgeRibbon &&
                                    <img
                                        className={`position-absolute mx-auto start-0 end-0 ${isAnimating ? classes.AnimatedRibbon : ''} ${classes.Z1}`}
                                        style={badgeCSS.Ribbon}
                                        src={badgeRibbon}
                                        alt="Ribbon"
                                    />
                                }
                            </>
                        )
                    )}
                </div>
                <img
                    className={`position-absolute mx-auto start-0 end-0 ${classes.BadgeOutline} ${classes.Z0}`}
                    src={badgeOutline}
                    alt="Outline"
                />
            </NavLink>
        </Col>
    );
};

export default BadgeComponent;
